import React, { Component } from "react";
import styles from "./Footer.module.scss";
import ContactPopup from "../ContactPopup";
import { PRIVACY, TERMS } from "../../routes/pages/constants";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  handleOpen = () => {
    this.setState({ isOpen: true });
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };
  render() {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.copyright}>
            © {new Date().getFullYear()} beatBread Limited<br></br>
            funding Music. advancing Independence.
          </div>
          <div>
            <span className={styles.contactUs} onClick={this.handleOpen}>
              Contact Us
            </span>

            <a href={PRIVACY} target="_blank" className={styles.privacyPolicy}>
              Privacy Policy
            </a>

            <a href={TERMS} target="_blank" className={styles.privacyPolicy}>
              Terms of Use
            </a>
          </div>
        </div>
        <ContactPopup
          isOpen={this.state.isOpen}
          onRequestClose={this.handleClose}
        />
      </>
    );
  }
}

export default Footer;
